@use 'sass:map';

$breakpoints: (
   small: 576px,
   medium: 768px,
   large: 992px,
   xlarge: 1200px,
   container: 1536px,
   xxlarge: 1600px,
   xxxlarge: 2000px,
);

@mixin mq($key) {
   $size: map.get($breakpoints, $key);

   @media (min-width: $size) {
      @content;
   }
}

$mainColor: #21293a;
$blueColor: #1d7dca;
$boxBackgroundColor: #fff;
$footerColor: #ecedf0;
$input-select: #f3f2f2;
$btnColor: #252f4a;
$headerColor: #54555a;

$borderColor: #f1f1f4;

$borderRadius: 0.45rem;
$borderRadiusBtn: 0.3rem;

$boxShadow: rgba(0, 0, 0, 0.03) 0px 3px 4px;

body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   box-sizing: border-box;
   background-color: var(--main);
}

a {
   text-decoration: none;
}

.Mui-selected {
   background-color: $mainColor !important;
   color: #fff !important;
   border-color: #000 !important;
}

body {
   .main-body {
      display: flex;
      max-width: 100%;
   }

   .container {
      width: calc(100% - 80px);
      background-color: #fcfcfc;
      padding: 20px 40px;
      max-width: 1620px;
      margin: 0 auto;
   }

   .disabled {
      pointer-events: none;
   }

   .btn-delete {
      background-color: #a51c1c;
      color: #fff;
      font-family: 'Gotham Greek Book';
      font-size: 14px;
      width: unset;
      padding: 12px 30px;
      border-radius: var(--border-radius-btn);
      border: none;
      cursor: pointer;
   }

   .skroutz-list-total {
      display: flex;
      align-items: center;
      font-size: 16px !important;
   }

   .euro-sign {
      line-height: 18px;
      font-size: 18px;
   }

   //Spinner
   .loading-spinner-container {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 5000;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   //Clients Page
   .clients-table {
      width: 100%;
      height: 631px;
      max-width: 1450px;
      background-color: #fff;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      font-family: 'Gotham Greek Book';
      margin-top: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      .MuiDataGrid-cellContent,
      .MuiDataGrid-columnHeaderTitle {
         font-family: 'Gotham Greek Book';
      }

      .MuiDataGrid-columnHeaders {
         background-color: $mainColor;
         color: #fff !important;

         svg {
            color: #fff !important;
         }

         input {
            background-color: #fff;
            color: #fff;
            border: 1px solid #fff;
            -webkit-tap-highlight-color: #fff !important;
            box-shadow: none;
         }
      }
   }

   //Invoices Page
   .invoices-table {
      width: 100%;
      height: 630px;
      background-color: #fff;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      font-family: 'Gotham Greek Book';
      margin-top: 10px;

      .MuiDataGrid-cellContent,
      .MuiDataGrid-columnHeaderTitle {
         font-family: 'Gotham Greek Book';
      }

      .MuiDataGrid-columnHeaders {
         background-color: $mainColor;
         color: #fff !important;

         svg {
            color: #fff !important;
         }

         input {
            background-color: #fff;
            color: #fff;
            border: 1px solid #fff;
            -webkit-tap-highlight-color: #fff !important;
            box-shadow: none;
         }
      }
   }

   // Skroutz orders page
   .skroutz-table {
      width: 100%;
      height: 900px;
      background-color: #fff;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      font-family: 'Gotham Greek Book';
      margin-top: 10px;

      .MuiDataGrid-cellContent,
      .MuiDataGrid-columnHeaderTitle {
         font-family: 'Gotham Greek Book';
      }

      .MuiDataGrid-columnHeaders {
         background-color: $mainColor;
         color: #fff !important;

         svg {
            color: #fff !important;
         }

         input {
            background-color: #fff;
            color: #fff;
            border: 1px solid #fff;
            -webkit-tap-highlight-color: #fff !important;
            box-shadow: none;
         }
      }
   }

   //User Page
   .customer-info {
      font-family: 'Gotham Greek Book';
   }

   //Users Page
   .users-page {
      .users-table {
         width: 100%;
         max-width: 900px;
         height: 400px;
         background-color: #fff;
         box-shadow: $boxShadow;
         border-radius: $borderRadius;
         font-family: 'Gotham Greek Book';
         margin-top: 40px;

         .MuiDataGrid-cellContent,
         .MuiDataGrid-columnHeaderTitle {
            font-family: 'Gotham Greek Book';
         }

         .MuiDataGrid-columnHeaders {
            background-color: $mainColor;
            color: #fff !important;

            svg {
               color: #fff !important;
            }

            input {
               background-color: #fff;
               color: #fff;
               border: 1px solid #fff;
               -webkit-tap-highlight-color: #fff !important;
               box-shadow: none;
            }
         }
      }
   }

   // Invoiced page
   .print-invoices-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 24px;
      font-size: 16px;
      padding: 10px 20px;
      border: none;
      font-family: 'Gotham Greek Book';
      border-radius: var(--border-radius);
      background-color: $input-select;
      cursor: pointer;
      width: fit-content;
   }

   .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
   .css-uhb5lp {
      max-width: unset;
   }

   .MuiTooltip-popper {
      font-size: 40px !important;
   }

   .printed-invoices-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 122vh;
      width: 1033px;
      overflow: visible;
      padding: 0 10px;

      font-family: 'Roboto', sans-serif;

      .total-prices {
         display: flex;
         flex-direction: column;
         gap: 20px;
         margin-top: 20px;
         padding-top: 20px;
         border-top: 1px solid #21293a;
         align-items: flex-end;
      }

      .title {
         display: flex;
         align-items: center;
         justify-content: space-between;
      }

      .header-in {
         background-color: #21293a;
         padding: 10px 0;
         color: #fff;
      }

      .single-line {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 20px;

         .canceled-in {
            background-color: #a51c1c;
            border-radius: 4px;
            color: white;
         }

         .in-mark {
            width: 135px;
            padding: 4px;
         }

         .in-type {
            width: 50px;
         }

         .in-date {
            width: 195px;
         }

         .in-series {
            width: 10px;
         }

         .in-aa {
            width: 20px;
         }

         .in-customerAFM {
            width: 80px;
         }

         .in-price {
            width: 60px;
         }

         .in-price2 {
            width: 70px;
            display: flex;
            align-items: center;
         }
      }
   }

   // single invoice page
   .delete-invoice-btn {
      background-color: #a51c1c;
      color: #fff;
      font-family: 'Gotham Greek Book';
      font-size: 14px;
      width: unset;
      padding: 12px 30px;
      border-radius: var(--border-radius-btn);
      border: none;
      cursor: pointer;
      align-items: center;
      width: -moz-fit-content;
      width: fit-content;
   }

   //Customer page
   .customers-btn-actions {
      display: flex;
      align-items: center;
      gap: 60px;
   }

   //Register page
   .register-page {
      .create-new-user {
         display: flex;
         flex-direction: column;
         gap: 20px;

         .new-user-input {
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 400px;

            position: relative;

            .show-pass-toggle {
               position: absolute;
               right: 10px;
               top: 50%;
               transform: translateY(-50%);
               font-family: 'Gotham Greek Book';
               cursor: pointer;
               font-size: 12px;
               margin: 0;
            }

            input {
               width: 100%;
            }

            .isAdminP {
               font-family: 'Gotham Greek Book';
               margin-right: 1rem;
            }
         }
      }
   }
}

@media screen and (max-width: 780px) {
   body .container {
      width: calc(100% - 30px);
      padding: 20px 15px;
   }
   .invoices-table,
   .clients-table,
   .skroutz-table {
      display: none !important;
   }
   // .invoices-table > *,
   // .clients-table > * {
   //    display: none !important;
   // }

   .customers-btn-actions {
      width: 100%;
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: flex-start;
      gap: unset !important;
   }

   .cancel-invoice-btns {
      display: flex !important;
      flex-direction: column;
      gap: 20px;
   }
   .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
      background-color: #21293a !important;
      color: #fff !important;
   }
}

.menu-try {
   position: relative;

   .main-element {
      span {
         color: white;
         font-size: 20px;
         font-weight: 500;
         cursor: pointer;
         padding: 10px 15px;
         background-color: rgb(230, 18, 219);
         width: fit-content;
         border-radius: 10px;
      }

      .sub-menu {
         display: none;
      }

      &:hover {
         .sub-menu {
            display: block;
         }
      }
   }
}

.display-skroutzMarketplace-table {
   font-family: 'Gotham Greek Book';

   @include mq(xlarge) {
      background-color: #fff;
      border-radius: $borderRadius;
      box-shadow: $boxShadow;
      border: 1px solid $borderColor;
      padding: 20px;
   }

   .table-header {
      display: none;

      @include mq(xlarge) {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         padding-bottom: 20px;
         border-bottom: 1px dashed #f1f1f4;
         font-weight: 600;
         font-size: 15px;
         color: $headerColor;

         display: grid;
         grid-template-columns:
            minmax(150px, 0.2fr) minmax(180px, 0.35fr) minmax(180px, 0.4fr) minmax(120px, 0.08fr) minmax(120px, 0.12fr) minmax(
               120px,
               0.05fr
            )
            minmax(120px, 0.05fr) minmax(90px, 0.05fr) 80px;
         gap: 8px;
         align-items: center;
      }
   }

   .table-body {
      display: block;

      .table-row {
         margin-bottom: 20px;
         padding: 20px;
         border-radius: $borderRadius;
         border: 1px solid $borderColor;
         background-color: #fff;
         box-shadow: $boxShadow;

         @include mq(xlarge) {
            padding: 20px 0 20px 0;
            margin-bottom: 0;
            box-shadow: none;
            border: none;
            border-bottom: 1px dashed #f1f1f4;

            display: grid;
            grid-template-columns:
               minmax(150px, 0.2fr) minmax(180px, 0.35fr) minmax(180px, 0.4fr) minmax(120px, 0.08fr) minmax(
                  120px,
                  0.12fr
               )
               minmax(120px, 0.05fr)
               minmax(120px, 0.05fr) minmax(90px, 0.05fr) 80px;
            gap: 8px;
            align-items: center;
            transition: all 0.2s ease-in;
         }

         .table-value {
            font-size: 15px;
            font-family: 'Gotham Greek Book';
            font-weight: 500;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            display: flex;
            flex-direction: column;
            gap: 5px;

            @include mq(xlarge) {
               margin-top: 0;
            }

            .title {
               font-size: 14px;

               @include mq(xlarge) {
                  display: none;
               }
            }

            .isOrderPayOnDeliverList {
               width: 10px;
               height: 10px;
               display: inline-block;
               border-radius: 100px;
               background-color: orange;
            }

            .bg-red {
               background-color: #a51c1c;
               color: #fff;
               padding: 2px 4px;
               border-radius: 5px;
               width: fit-content;
            }
         }

         .pc-only {
            display: none;

            @include mq(xlarge) {
               display: flex;
            }
         }

         .table-button {
            border: none;
            background-color: $input-select !important;
            border-radius: 5px;
            margin-top: 20px;
            cursor: pointer;
            padding: 10px;
            font-weight: 600;
            color: #252f4a;
            font-size: 13px;
            line-height: 17px;

            @include mq(xlarge) {
               margin-top: 0;
            }
         }
      }

      .table-row:last-child {
         border-bottom: none;
      }
   }

   .pagination-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
   }

   .no-invoices {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      text-align: center;
   }
}

// Single Invoice Page
.display-invoice {
   width: 100%;
   max-width: 1250px;
   box-sizing: border-box;
   padding: 20px;
   background-color: #fff;
   border-radius: 8px;
   margin-bottom: 30px;
   font-family: 'Roboto', sans-serif;
   line-height: 24px;

   @include mq(xlarge) {
      padding: 40px;
   }

   .invoice-header {
      display: flex;
      flex-direction: column;

      .vslu-data {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         margin-bottom: 15px;

         @include mq(large) {
            flex-direction: row;
         }

         .vslu-info {
            width: 100%;
         }

         .logo {
            text-align: center;
         }
      }

      .invoice-client {
         display: flex;
         flex-direction: column;
         align-items: center;

         .invoice {
            width: 100%;

            @include mq(large) {
               padding-top: 20px;
               margin-top: 20px;
               border-top: 1px solid #000;
            }

            .type {
               .mobile-title {
                  width: 100%;
                  text-align: center;
                  font-weight: 500;
                  border-bottom: 1px solid black;
                  padding-bottom: 4px;
                  margin-bottom: 10px;
                  color: #304075;
               }

               @include mq(large) {
                  font-size: 18px;
                  font-weight: 500;
               }

               .text {
                  text-align: center;
               }
            }

            .details {
               width: 100%;
               margin-top: 15px;

               @include mq(large) {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
               }

               .inv-details {
                  width: 100%;

                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 15px;

                  @include mq(large) {
                     width: unset;
                     flex-direction: row;
                     align-items: center;
                  }

                  .title-color {
                     width: 100%;
                     text-align: center;
                     font-size: 18;
                     font-weight: 500;
                     border-bottom: 1px solid black;
                     padding-bottom: 4px;
                     margin-bottom: 10px;
                     color: #304075;

                     @include mq(large) {
                        width: unset;
                        border-bottom: none;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        font-weight: normal;
                        margin-right: 5px;
                     }
                  }
               }
            }
         }

         .client {
            margin-top: 20px;
            margin-bottom: 50px;
            width: 100%;

            @include mq(large) {
               padding-top: 20px;
               margin-top: 20px;
               border-top: 1px solid #000;
            }

            .title {
               text-align: left;
               border-bottom: 1px solid #000;
               padding-bottom: 4px;
               font-weight: 500;
               margin-bottom: 15px;

               @include mq(large) {
                  text-align: center;
                  border-bottom: none;
                  font-size: 18px;

                  .title-color {
                     color: #000;
                  }
               }
            }

            .body {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 15px;

               @include mq(large) {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
               }

               .title-color {
                  min-width: 100px;
                  display: inline-block;
               }
            }
         }
      }
   }

   .invoice-body {
      @include mq(xlarge) {
         min-height: 300px;
      }
      .table-headers {
         display: none;

         @include mq(xlarge) {
            border-top: 1px solid #000;
            padding-top: 20px;

            display: grid;
            grid-template-columns:
               minmax(100px, 0.5fr) minmax(300px, 1.5fr) minmax(100px, 0.5fr) minmax(80px, 0.4fr) minmax(100px, 0.5fr)
               minmax(100px, 0.5fr) minmax(100px, 0.5fr);
         }

         div {
            @include mq(xlarge) {
               padding-right: 5px;
            }
         }
      }

      .table-body {
         .row {
            border-top: 1px solid #e8e8e8;
            padding-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px dashed $borderColor;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;

            @include mq(xlarge) {
               border-top: none;

               display: grid;
               grid-template-columns:
                  minmax(100px, 0.5fr) minmax(300px, 1.5fr) minmax(100px, 0.5fr) minmax(80px, 0.4fr) minmax(
                     100px,
                     0.5fr
                  )
                  minmax(100px, 0.5fr) minmax(100px, 0.5fr);
               gap: 0;
            }

            div {
               width: 100%;

               display: flex;
               align-items: baseline;
               justify-content: space-between;

               @include mq(xlarge) {
                  padding-right: 5px;
               }

               .mobile-title {
                  display: inline-block;
                  min-width: 110px;

                  @include mq(xlarge) {
                     display: none;
                     min-width: unset;
                  }
               }

               .desc {
                  text-align: right;

                  @include mq(xlarge) {
                     text-align: unset;
                  }
               }
            }
         }
      }
   }

   .invoice-footer {
      margin-top: 100px;

      @include mq(large) {
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
      }

      .aade-logo {
         display: none;

         @include mq(large) {
            display: block;
         }
      }

      .invoice-totals {
         width: 100%;
         font-size: 16px;

         @include mq(large) {
            max-width: 400px;
         }

         div {
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 4px;
            margin-bottom: 15px;

            display: flex;
            align-items: center;
            justify-content: space-between;
         }
      }
   }

   .title-color {
      color: #304075;
   }
}

// Customers Table
.customers-table {
   margin-top: 15px;
   box-sizing: border-box;
   font-family: 'Gotham Greek Book';

   @include mq(xlarge) {
      background-color: #fff;
      border-radius: $borderRadius;
      box-shadow: $boxShadow;
      border: 1px solid $borderColor;
      padding: 20px;
   }

   .table-header {
      display: none;
      background-color: #fff;
      border-radius: $borderRadius;

      @include mq(large) {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         padding-bottom: 20px;
         border-bottom: 1px dashed #f1f1f4;
         font-weight: 600;
         font-size: 15px;
         color: $headerColor;

         display: grid;
         grid-template-columns:
            minmax(200px, 0.8fr) minmax(80px, 0.4fr) minmax(150px, 0.6fr) minmax(130px, 0.5fr) minmax(100px, 0.5fr)
            minmax(100px, 0.5fr) minmax(40px, 0.2fr);
         align-items: center;
      }
   }
   .table-body {
      .row {
         margin-bottom: 20px;
         padding: 20px;
         border-radius: $borderRadius;
         border: 1px solid $borderColor;
         background-color: #fff;
         box-shadow: $boxShadow;

         @include mq(large) {
            padding: 20px 0 20px 0;
            margin-bottom: 0;
            box-shadow: none;
            border: none;
            border-bottom: 1px dashed #f1f1f4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            transition: all 0.2s ease-in;
            display: grid;
            grid-template-columns:
               minmax(200px, 0.8fr) minmax(80px, 0.4fr) minmax(150px, 0.6fr) minmax(130px, 0.5fr) minmax(100px, 0.5fr)
               minmax(100px, 0.5fr) minmax(40px, 0.2fr);
            gap: 5px;
            align-items: center;
         }

         div {
            font-size: 15px;
            font-family: 'Gotham Greek Book';
            font-weight: 500;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            display: flex;
            flex-direction: column;
            align-self: center;
            gap: 8px;

            @include mq(large) {
               line-height: 1.1em;
               max-height: 2.2em;
               overflow: hidden;
               white-space: normal;
               -webkit-line-clamp: 2;
               text-overflow: ellipsis;
               margin-top: 0px;
            }

            .value-title {
               font-size: 14px;
               color: #21293a;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;

               @include mq(large) {
                  display: none;
               }
            }
         }

         .table-button {
            border: none;
            background-color: $input-select !important;
            border-radius: 5px;
            margin-top: 20px;
            cursor: pointer;
            padding: 10px;
            font-weight: 600;
            color: #252f4a;
            font-size: 13px;
            line-height: 17px;

            @include mq(xlarge) {
               margin-top: 0;
            }
         }
      }

      .row:last-child {
         border-bottom: none;
      }
   }
   .pagination-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
   }

   .no-customers {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      text-align: center;
   }
}

.display-invoices-table {
   font-family: 'Gotham Greek Book';

   @include mq(xlarge) {
      background-color: #fff;
      border-radius: $borderRadius;
      box-shadow: $boxShadow;
      border: 1px solid $borderColor;
      padding: 20px;
   }

   .table-header {
      display: none;

      @include mq(xlarge) {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         padding-bottom: 20px;
         border-bottom: 1px dashed #f1f1f4;
         font-weight: 600;
         font-size: 15px;
         color: $headerColor;

         display: grid;
         grid-template-columns:
            minmax(160px, 1fr) minmax(180px, 1.6fr) minmax(80px, 1fr) minmax(80px, 0.4fr) minmax(80px, 0.4fr)
            minmax(160px, 1fr) minmax(90px, 0.9fr) minmax(90px, 0.9fr) minmax(90px, 0.9fr) 80px;
         gap: 5px;
         align-items: center;
      }
   }

   .table-body {
      display: block;

      .table-row {
         margin-bottom: 20px;
         padding: 20px;
         border-radius: $borderRadius;
         border: 1px solid $borderColor;
         background-color: #fff;
         box-shadow: $boxShadow;

         @include mq(xlarge) {
            padding: 20px 0 20px 0;
            margin-bottom: 0;
            box-shadow: none;
            border: none;
            border-bottom: 1px dashed #f1f1f4;

            display: grid;
            grid-template-columns:
               minmax(160px, 1fr) minmax(180px, 1.6fr) minmax(80px, 1fr) minmax(80px, 0.4fr) minmax(80px, 0.4fr)
               minmax(160px, 1fr) minmax(90px, 0.9fr) minmax(90px, 0.9fr) minmax(90px, 0.9fr) 80px;
            gap: 5px;
            align-items: center;
            transition: all 0.2s ease-in;
         }

         .table-value {
            font-size: 15px;
            font-family: 'Gotham Greek Book';
            font-weight: 500;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            display: flex;
            flex-direction: column;
            gap: 5px;

            @include mq(xlarge) {
               margin-top: 0;
            }

            .title {
               font-size: 14px;

               @include mq(xlarge) {
                  font-weight: 600;
                  display: none;
               }
            }

            .bg-red {
               background-color: #a51c1c;
               color: #fff;
               padding: 2px 4px;
               border-radius: 5px;
               width: fit-content;
            }
         }

         .pc-only {
            display: none;

            @include mq(xlarge) {
               display: flex;
            }
         }

         .table-button {
            border: none;
            background-color: $input-select !important;
            border-radius: 5px;
            margin-top: 20px;
            cursor: pointer;
            padding: 10px;
            font-weight: 600;
            color: #252f4a;
            font-size: 13px;
            line-height: 17px;

            @include mq(xlarge) {
               margin-top: 0;
            }
         }
      }

      .table-row:last-child {
         border-bottom: none;
      }
   }

   .pagination-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
   }

   .no-invoices {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      text-align: center;
   }
}

// Users Table
.Users-table {
   margin-top: 15px;
   box-sizing: border-box;
   font-family: 'Gotham Greek Book';

   @include mq(xlarge) {
      background-color: #fff;
      border-radius: $borderRadius;
      box-shadow: $boxShadow;
      border: 1px solid $borderColor;
      padding: 20px;
   }

   .table-header {
      display: none;
      background-color: #fff;
      border-radius: $borderRadius;

      @include mq(large) {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         padding-bottom: 20px;
         border-bottom: 1px dashed #f1f1f4;
         font-weight: 600;
         font-size: 15px;
         color: $headerColor;

         display: grid;
         grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 80px;
         align-items: center;
      }
   }
   .table-body {
      .row {
         margin-bottom: 20px;
         padding: 20px;
         border-radius: $borderRadius;
         border: 1px solid $borderColor;
         background-color: #fff;
         box-shadow: $boxShadow;

         @include mq(large) {
            padding: 20px 0 20px 0;
            margin-bottom: 0;
            box-shadow: none;
            border: none;
            border-bottom: 1px dashed #f1f1f4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            transition: all 0.2s ease-in;
            display: grid;
            display: grid;
            grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 80px;
            align-items: center;
         }

         div {
            font-size: 15px;
            font-family: 'Gotham Greek Book';
            font-weight: 500;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            display: flex;
            flex-direction: column;
            align-self: center;
            gap: 8px;

            @include mq(large) {
               line-height: 1.1em;
               max-height: 2.2em;
               overflow: hidden;
               white-space: normal;
               -webkit-line-clamp: 2;
               text-overflow: ellipsis;
            }

            .value-title {
               font-size: 14px;
               color: #21293a;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;

               @include mq(large) {
                  display: none;
               }
            }
         }
      }
      .flex-end {
         display: flex;
         align-items: flex-start;

         @include mq(large) {
            align-items: flex-end;
         }

         .table-button {
            border: none;
            background-color: $input-select !important;
            border-radius: 5px;
            margin-top: 20px;
            cursor: pointer;
            padding: 10px;
            font-weight: 600;
            color: #252f4a;
            font-size: 13px;
            line-height: 17px;

            @include mq(xlarge) {
               margin-top: 0;
            }
         }
      }

      .row:last-child {
         border-bottom: none;
      }
   }
   .pagination-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
   }

   .no-customers {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      text-align: center;
   }
}
