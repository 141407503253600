@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
   font-family: 'Gotham Greek Black';
   src: url('./assets/fonts/GothamGreek-Black.woff2') format('woff2'),
      url('./assets/fonts/GothamGreek-Black.woff') format('woff');
   font-weight: 900;
   font-style: normal;
   font-stretch: normal;
   unicode-range: U+0020-03CE;
}
@font-face {
   font-family: 'Gotham Greek Book';
   src: url('./assets/fonts/GothamGreek-Book.woff2') format('woff2'),
      url('./assets/fonts/GothamGreek-Book.woff') format('woff');
   font-weight: 400;
   font-style: normal;
   font-stretch: normal;
   unicode-range: U+0020-03CE;
}
@font-face {
   font-family: 'Gotham Greek Medium';
   src: url('./assets/fonts/GothamGreek-Medium.woff2') format('woff2'),
      url('./assets/fonts/GothamGreek-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-stretch: normal;
   unicode-range: U+0020-03CE;
}

/* @font-face {
   font-family: 'PF Beau Sans Pro';
   src: local('PF Beau Sans Pro Light'), local('PF-Beau-Sans-Pro-Light'),
      url('PFBeauSansPro-Light.woff2') format('woff2'), url('PFBeauSansPro-Light.woff') format('woff'),
      url('PFBeauSansPro-Light.ttf') format('truetype');
   font-weight: 300;
   font-style: normal;
} */

@font-face {
   font-family: 'PF Beau Sans Pro';
   src: url('./assets/fonts/PFBeauSansPro-Bbook.woff2') format('woff2'),
      url('./assets/fonts/PFBeauSansPro-Bbook.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}

/*================
Hide input arrows
=================*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type='number'] {
   -moz-appearance: textfield;
}

/*===============*/

:root {
   --main: #21293a;
   --blue: #1d7dca;
   --box-background: #fff;
   --footer: #ecedf0;
   --main-bg: #fcfcfc;
   --input-select: #f3f2f2;
   --hover-btn: #a1adbb;

   --border-radius: 0.45rem;
   --border-radius-btn: 0.3rem;
   --border-color: #f1f1f4;

   /* --box-shadow: 0 2px 4px 0 rgb(0 0 0 / 13%); */
   --box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px;
}

body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   box-sizing: border-box;
   background-color: var(--main);
   color: #1a1a1a;
}

a {
   text-decoration: none;
   color: inherit;
}

.list-arrow-icon {
   fill: var(--main) !important;
}

.main-body {
   display: flex;
   max-width: 100%;
}

.mg-top-10 {
   margin-top: 10px;
}

.flex-center {
   display: flex;
   align-items: center;
   gap: 20px;
}

.flex-column-w100 {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.flex-column {
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 20px;
   width: 33%;
   border-radius: var(--border-radius-btn);
   box-shadow: var(--box-shadow);
   font-family: 'Gotham Greek Medium';
   background-color: #fff;
   border: 1px solid #f1f1f4;
}

.mg-tb-1 {
   margin: 1rem 0;
}

.full-width-right {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

.disabled {
   pointer-events: none;
}

.mg-top-15 {
   margin-top: 1.5rem !important;
}

.mg-top-30 {
   margin-top: 3rem;
}

.mg-left-10 {
   margin-left: 1rem !important;
}

.outline-btn {
   background-color: none;
   color: var(--main);
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 10px 28px;
   border-radius: var(--border-radius-btn);
   border: 2px solid var(--main);
   cursor: pointer;
   width: fit-content;
}

.btn-delete {
   background-color: #a51c1c;
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 30px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
}

.color-red {
   color: #a51c1c;
}

.width-100 {
   width: 100% !important;
}

.probolh {
   display: flex;
   align-items: center;
   gap: 10px;
}

.pos-relative {
   position: relative;
}

.min-height-24 {
   min-height: 24px;
}

.ff-gotham-book {
   font-family: 'Gotham Greek Book' !important;
}

.ff-gotham-medium {
   font-family: 'Gotham Greek Medium' !important;
}

.small-spiner-flex {
   display: flex;
   align-items: center;
   gap: 10px;
   max-height: 36px;
}

.small-spiner-flex-center {
   display: flex;
   align-items: center;
   justify-content: center;
   max-height: 36px;
}

.table-field-link {
   overflow: hidden;
   text-overflow: ellipsis !important;
   text-decoration: none;
   color: rgba(0, 0, 0, 0.87);
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   white-space: nowrap;
   -webkit-font-smoothing: antialiased;
}

.anchor-dec {
   text-decoration: none;
   color: #000000de;
   font-family: 'Gotham Greek Book';
}

.rdrCalendarWrapper {
   border-radius: var(--border-radius);
   border: 1px solid var(--border-color);
   box-shadow: var(--box-shadow);
}

.rdrDateDisplayWrapper {
   background-color: #fff !important;
   border-radius: var(--border-radius);
   border-bottom: 1px dashed var(--border-color);
}

/*================
Pagination
=================*/
.pagination-container {
   margin-top: 2rem;
}

.pagination-ul {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 10px;
   padding: 0;
   flex-wrap: wrap;
}

.pagination-li {
   list-style: none;
}

.pagination-li a {
   text-decoration: none;
   background-color: var(--main);
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 20px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
}

.pagination-active {
   background-color: var(--blue) !important;
}

.pagination-minus {
   text-decoration: none;
   background-color: var(--main);
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 20px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
}

.pagination-list li button {
   font-family: 'Gotham Greek Book' !important;
}

.Mui-selected {
   border: none !important;
   background-color: var(--main);
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
   border: none !important;
}

/*================
Header
=================*/

header {
   position: relative;
}

.header {
   width: 250px;
   position: sticky;
   left: 0;
   min-height: 100vh;
   background-color: var(--main);
}

.header-active {
   width: 250px;
}

.nav-logo {
   font-family: 'Gotham Greek Medium';
   font-size: 25px;
   color: var(--blue);
   margin: 40px 0px 10px 30px;
   margin-top: 40px;
   cursor: pointer;
   background-color: #f1f1f1;
   border-radius: 4px;
   padding: 10px;
   width: fit-content;
}
.nav-svg {
   font-family: 'Gotham Greek Medium';
   font-size: 25px;
   color: var(--blue);
   margin: 20px 0px 10px 30px;
   cursor: pointer;
   width: 160px;
}
.nav-container {
   background-color: var(--main);
   position: relative;
}

.navbarUl {
   display: flex;
   flex-direction: column;
   margin: 20px;
   padding: 0;
}

.navbarUl li {
   list-style: none;
   font-family: 'Gotham Greek Medium';
   padding-left: 10px;
}

.navbarUl li:hover {
   background-color: rgba(255, 255, 255, 0.2);
   width: 90%;
   border-radius: var(--border-radius);
}

.navbarListItem {
   cursor: pointer !important;
   display: flex;
   align-items: center;
   gap: 10px;
}

.navbarListItem-dropdown {
   cursor: pointer !important;
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   transition: all 0.3s ease;
}

.create-invoice {
   display: flex;
   align-items: center;
   gap: 10px;
}

.navbarListItemNameActive {
   color: var(--blue);
}

.navbarListItem {
   cursor: pointer !important;
}

.navbarListItemName {
   color: #fff;
}

.nav-submenu-visible {
   display: flex;
   flex-direction: column;
   margin-bottom: 5px;
   margin-left: 15px;
   gap: 14px;
   color: #fff;
   transition: all 0.7s ease;
   visibility: visible;
   opacity: 1;
}

.nav-submenu-hidden {
   visibility: hidden;
   opacity: 0;
   transition: visibility 0s, opacity 0.6s ease;
   position: absolute;
}

.nav-submenu-visible span:hover {
   color: var(--blue);
}

.toggle-Menu {
   font-size: 20px;
   font-family: 'Gotham Greek Black';
   color: white;
   position: absolute;
   top: 10px;
   right: 10px;
   cursor: pointer;
   display: none;
}

.dropdown-active {
   -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   -o-transform: rotate(180deg);
   transform: rotate(180deg);
   transition: all 0.3s ease;
}

.noth {
   transition: all 0.3s ease;
}

/*======================*/

/*===============
Input fields
=================*/

.page-content {
   width: 100%;
   margin: 0 auto;
   background-color: var(--main-bg);
   position: relative;
   overflow: hidden;
   text-overflow: ellipsis;
   min-height: calc(100vh - 20px);
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.container {
   width: calc(100% - 80px);
   background-color: var(--main-bg);
   padding: 20px 40px;
}

.invoice-container-flex {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

label {
   font-family: 'Gotham Greek Book';
}

select,
input {
   padding: 14px 20px;
   border: none;
   font-family: 'Gotham Greek Book';
   border-radius: var(--border-radius);
   background-color: #f3f2f2;
   color: #1a1a1a;
   cursor: pointer;
   min-width: 80px;
}

select {
   -webkit-appearance: none;
   -moz-appearance: none;
   text-indent: 1px;
   text-overflow: '';
   position: relative;
}

select {
   --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2378829D' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
   display: block;
   font-weight: 500;
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2378829D' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right 1rem center;
   background-size: 16px 12px;
   border-radius: 0.475rem;
}

input::placeholder {
   color: #1a1a1a;
   opacity: 1; /* Firefox */
}

input::-ms-input-placeholder {
   /* Edge 12 -18 */
   color: #1a1a1a;
}

.customer-search {
   padding: 10px 25px;
   width: 400px;
   margin: 15px 0;
   color: #1a1a1a;
}

select option:hover {
   background-color: var(--blue);
}

.flex-center-0 {
   display: flex;
   align-items: center;
   gap: 0;
}

.flex-center-5 {
   display: flex;
   align-items: center;
   gap: 5px;
}

.height-45 {
   height: 45px;
   font-size: 16px;
}

.btn-submit {
   background-color: var(--main);
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 30px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
}

.btn-update {
   background-color: var(--main);
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 30px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
   display: flex;
   align-items: center;
   gap: 5px;
}

.btn-bg {
   background-color: var(--main);
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 30px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
   display: flex;
   align-items: center;
   width: -moz-fit-content;
   width: fit-content;
   gap: 10px;
}

.btn-back {
   background-color: var(--main);
   color: #fff;
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 12px 30px;
   border-radius: var(--border-radius-btn);
   border: none;
   cursor: pointer;
   display: flex;
   align-items: center;
   width: fit-content;
   gap: 10px;
}

.dialog-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.top-left-corner {
   margin-top: 20px;
   border-top-left-radius: 30px;
}

.page-title {
   font-family: 'Gotham Greek Medium';
   margin: 20px 0;
   position: relative;
   margin-bottom: 1.5rem;
}

.page-title::before {
   content: '';
   position: absolute;
   height: 2px;
   width: 80px;
   background-color: var(--main);
   left: 0;
   bottom: -10px;
}

.apodeixhForm {
   display: flex;
   flex-direction: column;
}
.mg-1 {
   margin: 1rem;
}

.mg-1-0 {
   margin: 1rem 0;
}

.min-width-120 {
   min-width: 120px;
}

.label-flex-input {
   display: flex;
   align-items: center;
   justify-content: baseline;
   gap: 20px;
   margin-bottom: 20px;
}

.box {
   margin-bottom: 20px;
   padding: 20px;
   border-radius: var(--border-radius);
   border: 1px solid #f1f1f4;
   background-color: #fff;
   box-shadow: var(--box-shadow);
}

.invoice-box {
   margin-bottom: 20px;
   padding: 22px 32px 0 32px;
   background-color: var(--box-background);
   max-width: 1023px;
   position: relative;
}

.isVoid {
   position: absolute;
   font-size: 52px;
   padding: 10px;
   color: #a51c1c;
   border: 5px solid #a51c1c;
   border-radius: 5px;
   font-family: 'Roboto', sans-serif;
   font-weight: bold;
   top: 50%;
   left: 40%;
   transform: rotate(-30deg);
}

.invoice-max-width {
   max-width: 1023px;
}

.add-product {
   /* min-width: 1450px;
   min-height: 200px; */
   overflow: auto;
}

.product-row {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
   gap: 15px;
   margin-bottom: 30px;
}

.remove-btn {
   background-color: #f53939;
   border: none;
   color: #fff;
   font-family: 'Gotham Greek Medium';
   font-size: 18px;
   padding: 6px 10px;
   border-radius: var(--border-radius-btn);
   cursor: pointer;
   margin-top: 10px;
}

.invalid-input {
   color: #f53939;
   font-family: 'Gotham Greek Book';
   font-size: 12px;
   margin: 10px 0;
}

/*==============
Product Table
===============*/
.product-table {
   width: 100%;
   margin-top: 10px;
   margin-bottom: 50px;
   min-width: 1450px;
   overflow: auto;
}

.product-table-head {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 20px;
   width: 100%;
}

.product-table-body {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 20px;
   width: 100%;
   margin-bottom: 10px;
}

.product-table .table-head {
   background-color: var(--main);
   padding-top: 10px;
   padding-bottom: 10px;
   color: white;
   border-radius: var(--border-radius);
   font-family: 'Gotham Greek Book';
   font-size: 15px;
}

.product-table .product-table-head .table-head-span {
   padding-left: 20px;
}

.product-table .product-table-head .p-x {
   visibility: hidden;
   padding: 10px 20px;
}

.p-id {
   width: 10%;
}

.p-name {
   width: 40%;
}

.p-unit {
   width: 10%;
}

.p-quantity {
   width: 9%;
}

.p-price {
   width: 9%;
}

.p-tax {
   width: 10%;
}

.p-x {
   width: 2%;
}

.product-table-body input,
.product-table-body select {
   width: 100% !important;
   box-sizing: border-box;
   margin-top: 10px;
}

.products-prices-summary {
   font-family: 'Gotham Greek Medium';
   position: relative;
   margin-top: 40px;
}

/* .products-prices-summary::before {
   content: '';
   position: absolute;
   width: 200px;
   height: 2px;
   top: -15px;
   left: 0;
   background-color: var(--main);
} */

/*==============
Footer
===============*/

footer {
   width: 100%;
   margin: 0 auto;
   background-color: var(--main-bg);
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 14px;
}

.footer-version {
   font-family: 'Gotham Greek Medium';
   padding: 10px 40px;
}

.footer-usefool-links {
   padding: 10px 40px;
}

.footer-usefool-links ul {
   display: flex;
   font-family: 'Gotham Greek Medium';
}

.footer-usefool-links ul li {
   list-style: none;
   padding: 0 10px;
   cursor: pointer;
   text-decoration: none;
}

.footer-usefool-links ul li a {
   text-decoration: none;
   color: var(--main);
}

.footer-usefool-links ul li:first-child {
   padding-left: 0;
}

.footer-usefool-links ul li:last-child {
   padding-right: 0;
}

/*==============
Home
===============*/

.usefullLinks-container {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 40px 0;
}
.usefullLink {
   margin-right: 1rem;
}
.usefullLink a {
   text-decoration: none;
   color: var(--main);
   background-color: var(--input-select);
   font-family: 'Gotham Greek Book';
   cursor: pointer;
   padding: 10px 20px;
   border: none;
   border-radius: var(--border-radius);
   box-shadow: var(--box-shadow);
   font-size: 15px;
}

.usefullLink a:hover {
   background-color: var(--hover-btn);
}

.monthly-stats {
   display: flex;
   align-items: center;
   justify-content: space-between;
   overflow: auto;
}

.monthly-stats::-webkit-scrollbar {
   height: 4px;
   width: 4px;
   background: var(--footer);
}
.monthly-stats::-webkit-scrollbar-thumb:horizontal {
   background: var(--main);
   border-radius: 10px;
}

.stats-items {
   flex: 1;
   margin: 0 20px;
   padding: 30px;
   border-radius: var(--border-radius);
   box-shadow: var(--box-shadow);
   border: 1px solid #f1f1f4;
   display: flex;
   flex-direction: column;
   font-family: 'Gotham Greek Book';
   gap: 10px;
   background-color: #fff;
   min-width: 300px;
}

.stats-item-title {
   color: #484b53;
   font-family: 'Gotham Greek Book';
   font-size: 1.2rem;
}

.stats-item-net,
.stats-item-vat {
   font-family: 'Gotham Greek Book';
   font-size: 1rem;
}

.stats-item-total {
   font-family: 'Gotham Greek Medium';
   font-size: 1.5rem;
   margin-top: 10px;
}

.stats-items:first-child {
   margin-left: 0;
}

.home-last-three-months {
   width: fit-content;
   margin-top: 20px;
}

.home-last-three-months .stats-items {
   width: 444px;
}

/*==============
Loading Spinner
===============*/

.loadingSpinnerContainer {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: 5000;
   display: flex;
   justify-content: center;
   align-items: center;
}

.loadingSpinner {
   width: 64px;
   height: 64px;
   border: 8px solid;
   border-color: #000 transparent #555 transparent;
   border-radius: 50%;
   animation: spin 1.2s linear infinite;
}

.loadingSpinnerContainer2 {
   width: 100%;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: 5000;
   display: flex;
   justify-content: center;
   align-items: center;
}

.loadingSpinner2 {
   width: 64px;
   height: 64px;
   border: 8px solid;
   border-color: #000 transparent #555 transparent;
   border-radius: 50%;
   animation: spin 1.2s linear infinite;
}

/*==============
Login
===============*/
.login-page {
   min-width: 100%;
   min-height: 100vh;
   z-index: 100;
   position: absolute;
   background-color: #f1f1f1;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 40px;
   overflow: hidden;
}

.page-title-login {
   font-family: 'Gotham Greek Medium';
   margin: 20px 0;
   font-size: 1.8rem;
   position: relative;
   margin-bottom: 1rem;
   padding: 1rem;
   color: var(--blue);
   width: 200px;
}

/* .login-page::before {
   content: url(./assets/icons/invoice-bg.png);
   position: absolute;
   transform: rotate(20deg);
   bottom: -400px;
   left: -125px;
} */

.login-page .login-input {
   margin-bottom: 10px;
}

.login-page .page-title::before {
   width: 100%;
}

.box-login {
   padding: 40px 80px 80px 80px;
   border-radius: 16px;
   background-color: #f8f8f8;
   width: fit-content;
   border: 1px solid #e8e8e8;
}

.login-form-titlle {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
   padding: 20px 0px;
   margin-bottom: 30px;
}

.login-form-titlle-top {
   font-family: 'Gotham Greek Medium';
   margin: 0;
   font-size: 1.2rem;
   position: relative;
}

.login-form-titlle-text {
   font-family: 'Gotham Greek Medium';
   margin: 0;
   font-size: 0.8rem;
   position: relative;
}

.login-form-titlle-top::before {
   content: '';
   position: absolute;
   width: 30px;
   background-color: #000;
   top: 11px;
   left: -40px;
   height: 2px;
}

.login-form-titlle-top::after {
   content: '';
   position: absolute;
   width: 30px;
   background-color: #000;
   top: 11px;
   right: -40px;
   height: 2px;
}

.login-input {
   padding: 10px 20px;
   border: 1px solid var(--main);
   font-family: 'Gotham Greek Book';
   border-radius: var(--border-radius);
   box-shadow: var(--box-shadow);
   font-size: 16px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.login-input input {
   border: unset;
   padding: unset;
   box-shadow: unset;
   border-radius: unset;
   box-shadow: unset;
}

.login-input input:focus {
   outline: none;
}

.payment-type {
   display: flex;
   align-items: center;
}

.show-pass-toggle {
   font-family: 'Gotham Greek Book';
   cursor: pointer;
   font-size: 12px;
   margin: 0;
}

/*==============
Customers
===============*/
.customers-top-btns {
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 20px;
}

.customers-container {
   width: 100%;
   margin-top: 10px;
   margin-bottom: 50px;
   min-width: 1450px;
   overflow: auto;
   min-width: 1050px;
}

.customers-headings {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 0px;
   width: 100%;
}

.customer-table-body {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 0px;
   width: fit-content;
   margin-bottom: 5px;
   margin-top: 5px;
   position: relative;
}

.create-customer-form input {
   width: 400px;
   text-overflow: ellipsis;
}

.customer-table-body:nth-child(even) {
   background-color: var(--box-background);
}
.customer-table-body:nth-child(odd) {
   background: #dbd8d8;
}

.customer-table-body:last-child {
   border-bottom-left-radius: var(--border-radius);
   border-bottom-right-radius: var(--border-radius);
}

.customers-border {
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: 18px;
   font-family: 'Gotham Greek Book';
}

.customers-headings .table-head {
   background-color: var(--main);
   padding-top: 14px;
   padding-bottom: 14px;
   color: white;
   font-family: 'Gotham Greek Book';
   font-size: 15px;
   padding-left: 18px;
}

.customers-headings .table-head:first-child {
   border-top-left-radius: var(--border-radius);
}

.customers-headings .table-head:last-child {
   border-top-right-radius: var(--border-radius);
}

.customers-container .customer-table-head .table-head-span {
   padding-left: 20px;
}

.customers-container .customer-table-head .p-x {
   visibility: hidden;
   padding: 10px 20px;
}

.c-name {
   max-width: 200px;
   min-width: 200px;
   overflow: hidden;
}

.c-afm {
   max-width: 200px;
   min-width: 200px;
   overflow: hidden;
}

.c-address {
   max-width: 200px;
   min-width: 200px;
   overflow: hidden;
}

.c-view {
   max-width: 200px;
   min-width: 200px;
   overflow: hidden;
}

.customers-mobile-details {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-family: 'Gotham Greek Book';
   gap: 10px;
}

.customers-mobile-details .details-field {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-family: 'Gotham Greek Book';
   gap: 2px;
}

.customers-details-title {
   font-size: 15px;
   color: #8099ac;
}

/*==============
Customer Page
===============*/
.customer-info {
   display: flex;
   margin-bottom: 0.35rem;
}

.customer-info .min-width-120 {
   padding: 10px 20px;
   padding-left: 0;
   font-family: 'Gotham Greek Book';
}

.customer-info-data {
   padding: 10px 20px;
   border: 1px solid var(--main);
   font-family: 'Gotham Greek Book';
   border-radius: var(--border-radius);
   box-shadow: var(--box-shadow);
   min-width: 250px;
   max-width: 250px;
   overflow: hidden;
}

.customer-info input {
   border: unset;
   padding: unset;
   margin: unset;
   box-shadow: unset;
   border-radius: unset;
   color: #000;
}

.customer-info input:focus {
   outline: none;
}

.new-customer-afm-search {
   display: flex;
   align-items: center;
   gap: 20px;
}

/*==============
Invoices
===============*/

.create-invoices {
   display: flex;
   gap: 10px;
}

.inv-date {
   max-width: 105px;
   min-width: 105px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.inv-series {
   max-width: 70px;
   min-width: 70px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.inv-aa {
   max-width: 30px;
   min-width: 30px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.inv-prices {
   max-width: 130px;
   min-width: 130px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.rdrDateInput input {
   padding: unset;
   border: unset;
   font-family: 'Gotham Greek Book';
   border-radius: unset;
   box-shadow: unset;
}

.calendar-input {
   padding: 10px 25px;
   width: fit-content;
   margin: 15px 0;
   border: none;
   background-color: var(--input-select);
   font-family: 'Gotham Greek Book';
   border-radius: var(--border-radius);
   display: flex;
   align-items: center;
   gap: 10px;
}

.display-date-range {
   cursor: pointer;
   color: #1a1a1a;
}

.date {
   position: absolute;
   left: 0;
   top: 50px;
   z-index: 2;
}

.invoice-delete {
   background-color: #a51c1c;
   padding: 4px;
   border-radius: 4px;
   color: white;
   font-family: 'Gotham Greek Book';
}

.invoice-list-mark {
   font-family: 'Gotham Greek Book';
   color: #000;
}

.invoice-mobile-details {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 5px;
   margin-bottom: 10px;
   font-family: 'Gotham Greek Book';
}

.invoice-mobile-details span {
   display: flex;
   align-items: center;
}

/*==============
Invoice
===============*/

.logo-and-vslu-info {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.logo-container {
   width: 33%;
   text-align: center;
}

.qrcode-container {
   width: 33%;
   text-align: right;
}

.vslu-info {
   width: 33%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 4px;
}

.vslu-info p {
   margin: 0;
   padding: 0;
   font-size: 17px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}

.vslu-info p:first-child {
   font-size: 22px;
}

.invoice-info-container {
   margin-top: 30px;
   border-top: 1px solid var(--main);
}

.invoice-type {
   text-align: center;
   width: 100%;
   font-size: 22px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
   margin-top: 10px;
}

.invoice-general-info {
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 17px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}

.invoice-general-info p {
   max-width: 350px;
   max-height: 40px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.invoice-customer-info {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 15px;
   font-size: 17px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}

.invoice-customer-info p {
   margin: 1em 0 0 0;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2; /* number of lines to show */
   line-height: 20px; /* fallback */
   max-height: 40px; /* fallback */
}

.products-container {
   margin-top: 30px;
   border-top: 1px solid var(--main);
   padding-top: 10px;
   min-height: 40vh;
}

.invoice-product-titles {
   display: flex;
   align-items: center;
   font-size: 18px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}

.invoice-table-body {
   display: flex;
   align-items: flex-start;
   font-size: 17px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
   margin-top: 10px;
}

.invoice-product-code {
   min-width: 50px;
   max-width: 50px;
   overflow: hidden;
   border-right: 1px solid var(--main);
   margin-right: 10px;
   text-overflow: ellipsis;
}

.invoice-product-name {
   min-width: 350px;
   max-width: 350px;
   overflow: hidden;
   border-right: 1px solid var(--main);
   margin-right: 10px;
   text-overflow: ellipsis;
}

.invoice-product-qty {
   min-width: 100px;
   max-width: 100px;
   overflow: hidden;
   border-right: 1px solid var(--main);
   margin-right: 10px;
   text-overflow: ellipsis;
}

.invoice-product-mm {
   min-width: 70px;
   max-width: 70px;
   overflow: hidden;
   border-right: 1px solid var(--main);
   margin-right: 10px;
   text-overflow: ellipsis;
}

.invoice-product-net-price {
   min-width: 130px;
   max-width: 130px;
   overflow: hidden;
   border-right: 1px solid var(--main);
   margin-right: 10px;
   text-overflow: ellipsis;
}

.invoice-product-fpa-perc {
   min-width: 100px;
   max-width: 100px;
   overflow: hidden;
   border-right: 1px solid var(--main);
   margin-right: 10px;
   text-overflow: ellipsis;
}

.invoice-product-final-price {
   min-width: 130px;
   max-width: 130px;
   overflow: hidden;
   padding-left: 10px !important;
   padding-right: 20px !important;
   text-align: right;
   text-overflow: ellipsis;
}

.invoice-totals-container {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 30px;
   border-top: 1px solid var(--main);
   gap: 10px;
   padding-top: 10px;
}

.aade-logo {
   display: block;
}

.bottom-aade {
   display: flex;
   align-items: center;
   gap: 34px;
}

.invoice-totals {
   display: inline-block;
   min-width: 80px;
   text-align: right;
   padding-left: 4px;
   float: right;
}

.top-aade {
   font-family: 'Roboto', sans-serif;
   font-size: 15px;
   color: #505052;
}

.logo-text {
   position: relative;
}

.logo-text::before {
   content: '';
   position: absolute;
   background-color: #162e60;
   width: 2px;
   height: 70%;
   right: -16px;
   top: 6px;
}

.timologio-text {
   font-family: 'PF Beau Sans Pro';
   font-size: 20px;
   margin-bottom: 8px;
   color: #162e60;
}

.invoice-totals-border {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
   margin-top: 8px;
}

.invoice-totals-container p {
   margin: 0;
   padding: 0;
   font-size: 17px;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}

.invoice-template {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   min-height: 95vh;
}

.invoice-footer {
   font-family: 'Roboto', sans-serif;
   font-size: 12px;
   margin-top: 20px;
}

.invoice-product-qty,
.invoice-product-mm,
.invoice-product-net-price,
.invoice-product-fpa-perc {
   text-align: end;
   padding-right: 10px;
   margin-right: 0 !important;
}

.product-final-price {
   text-align: end;
}

.invoice-tax-analysis {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}

.invoice-tax-analysis-title {
   margin-bottom: 10px;
   position: relative;
   border-bottom: 1px solid #000;
   width: 100%;
   text-align: center;
}

.title-color {
   color: #304075;
}

/* .invoice-tax-analysis-title::before {
   content: '';
   position: absolute;
   width: 100%;
   height: 2px;
   background-color: #000;
   bottom: -5px;
} */

.invoice-analysis-details {
   display: flex;
   gap: 30px;
}

.invoice-analysis-flex-column {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   gap: 10px;
   position: relative;
}

.invoice-analysis-flex-column::before {
   content: '';
   position: absolute;
   width: 1px;
   height: 100%;
   background-color: #000;
   right: -15px;
}

.invoice-analysis-flex-column:last-child::before {
   height: 0;
}

.invoice-totals-border p {
   margin-bottom: 10px;
   position: relative;
   width: 100%;
}

.invoice-totals-border p::before {
   content: '';
   position: absolute;
   width: 100%;
   height: 1px;
   background-color: #000;
   bottom: -4px;
}

/*==============
Timologio
===============*/

.customer-search-container {
   position: relative;
}

.customer-search-results {
   position: absolute;
   padding: 10px;
   top: 72px;
   left: 20px;
   background-color: white;
   max-height: 300px;
   overflow-y: auto;
   width: 430px;
   border-left: 1px solid var(--main);
   border-right: 1px solid var(--main);
   border-bottom: 1px solid var(--main);
   border-bottom-left-radius: var(--border-radius);
   border-bottom-right-radius: var(--border-radius);
   font-size: 14px;
   font-family: 'Gotham Greek Medium';
}

.picked-customer-info {
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-family: 'Gotham Greek Book';
   font-size: 15px;
   flex-wrap: wrap;
   gap: 10px;
}

.picked-customer-title {
   background-color: var(--main);
   padding: 10px;
   color: white;
   border-radius: var(--border-radius);
   width: 100px;
}

.picked-customer-data {
   padding: 10px;
   border: 1px solid var(--main);
   border-radius: var(--border-radius);
   min-height: 14px;
}

.customer-search-select:hover {
   cursor: pointer;
}

.cp-name {
   width: 300px;
   overflow: hidden;
}

.cp-afm {
   width: 300px;
   overflow: hidden;
}

.cp-doy {
   width: 300px;
   overflow: hidden;
}

.cp-prof {
   width: 300px;
   overflow: hidden;
}

/*==============
Skroutz Order page
===============*/

.skroutz-order-state-list {
   border-radius: 10px;
   padding: 4px 15px;
   color: #fff;
   font-family: 'Gotham Greek Book';
   width: fit-content;
   font-size: 13px;
}

.skroutz-order-state {
   border-radius: 10px;
   margin-left: 10px;
   padding: 4px 15px;
   color: #fff;
   font-family: 'Gotham Greek Book';
}

.open {
   background-color: #f68b24;
}

.open-pay {
   background-color: #bea73f;
}

.accepted {
   background-color: #155c96;
}

.delivered,
.dispatched {
   background-color: #084e08;
}

.returned,
.rejected,
.cancelled,
.expired,
.partially_returned,
.for_return {
   background-color: red;
}

.skroutz-order-state .open,
.skroutz-order-state .accepted {
   background-color: #155c96;
}

.skroutz-order-state .delivered,
.skroutz-order-state .dispatched {
   background-color: #084e08;
}

.skroutz-order-state .returned,
.skroutz-order-state .rejected,
.skroutz-order-state .cancelled,
.skroutz-order-state .expired,
.skroutz-order-state .partially_returned,
.skroutz-order-state .for_return {
   background-color: red;
}

.skroutz-flex-container {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   gap: 20px;
}

.skroutz-products-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 20px;
   flex: 2;
}

.skroutz-single-product-list {
   border-radius: var(--border-radius-btn);
   box-shadow: var(--box-shadow);
   border: 1px solid var(--border-color);
   background-color: #fff;
   width: 100%;
   font-size: 15px;
}

.skroutz-single-product-name-quantity {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   padding: 20px;
   font-family: 'Gotham Greek Medium';
}

.skroutz-single-product-uid {
   padding: 15px;
   font-family: 'Gotham Greek Book';
   display: flex;
   justify-content: space-between;
   font-size: 15px;
}

.skroutz-single-product-vat {
   padding: 20px;
   font-family: 'Gotham Greek Book';
   display: flex;
   gap: 4px;
}

.skroutz-client-container {
   font-family: 'Gotham Greek Medium';
   width: 100%;
   margin-top: 50px;
   display: flex;
   gap: 20px;
   font-size: 15px;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   font-size: 15px !important;
}

.flex-column-client {
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 20px;
   width: 33%;
   border-radius: var(--border-radius-btn);
   box-shadow: var(--box-shadow);
   font-family: 'Gotham Greek Medium';
   background-color: #fff;
   border: 1px solid #f1f1f4;
   width: calc(100% - 40px);
}

.skroutz-left-col {
   width: 100%;
}

.skroutz-right-column {
   display: flex;
   flex-direction: column;
   gap: 20px;
   font-size: 15px !important;
   min-width: 290px;
}

.skroutz-courier,
.skroutz-accept-order-container {
   flex: 1;
   border-radius: var(--border-radius-btn);
   box-shadow: var(--box-shadow);
   border: 1px solid var(--border-color);
   display: flex;
   flex-direction: column;
   padding: 20px;
   flex: 1;
   height: 100%;
   font-family: 'Gotham Greek Medium';
   gap: 20px;
   background-color: #fff;
}

.skroutz-order-voucher {
   width: 100%;
   background-color: var(--main);
   color: #fff;
   border-radius: var(--border-radius-btn);
   border: 1px solid #000;
   text-align: center;
   padding: 8px 0px;
   cursor: pointer;
   font-size: 15px;
   font-weight: normal;
}

.mark-input {
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-top: 20px;
}

.skroutz-site-look {
   border-radius: var(--border-radius-btn);
   box-shadow: var(--box-shadow);
   border: 1px solid var(--border-color);
   padding: 20px;
   height: 100%;
   font-family: 'Gotham Greek Medium';
   background-color: #fff;
   display: flex;
   align-items: center;
   gap: 6px;
}

.isOrderPayOnDelivery {
   display: flex;
   flex-direction: column;
   gap: 10px;
   font-family: 'Gotham Greek Medium';
   border-radius: var(--border-radius-btn);
   box-shadow: var(--box-shadow);
   background-color: #fff;
   padding: 20px;
   border: 1px solid var(--border-color);
}

.isOrderPayOnDelivery .payOnDeliveryTitle {
   font-size: 15px;
}

.isOrderPayOnDelivery .payOnDeliveryByttons {
   display: flex;
   align-items: center;
   gap: 10px;
}

.isOrderPayOnDelivery .payOnDeliveryByttons button {
   background-color: none;
   color: var(--main);
   font-family: 'Gotham Greek Book';
   font-size: 14px;
   width: unset;
   padding: 10px 28px;
   border-radius: 24px;
   border: unset;
   cursor: pointer;
   width: fit-content;
}

.isOrderPayOnDelivery .payOnDeliveryByttons button.isPayOnDel {
   background-color: var(--main);
   color: #fff;
}

.skroutz-outer-link {
   display: flex;
   align-items: center;
   gap: 6px;
}

.skroutz-client-info {
   padding: 20px;
}

.skroutz-accept-order-container {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.btn-submit-skroutz-order {
   width: 100%;
   padding: 10px 0;
   border: unset;
   border-radius: var(--border-radius-btn);
   color: #fff;
   font-family: 'Gotham Greek Medium';
   font-size: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
   cursor: pointer;
}

.skroutz-client-title {
   font-size: 16px;
   position: relative;
}

.skroutz-client-title::before {
   position: absolute;
   content: '';
   width: 100%;
   height: 2px;
   background-color: var(--main);
   left: 0;
   bottom: -4px;
}

.accept {
   background-color: #084e08;
}

.decline {
   background-color: red;
}

.order-date-mobile {
   font-size: 18px;
   font-family: 'Gotham Greek Medium';
}

.invoices-list-table {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 15px;
   margin-bottom: 10px;
   font-family: 'Gotham Greek Book';
}

.max-w-50 {
   max-width: 50px;
}

.skroutz-marketplace-box {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 15px;
   margin-bottom: 10px;
   font-family: 'Gotham Greek Book';
}

/*==============
NotFound page
===============*/

.notfound-page-text {
   width: 100%;
   height: 100%;
   font-family: 'Gotham Greek Medium';
   font-size: 2rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.skroutz-order-note {
   display: flex;
   padding: 20px 0 0 20px;
   font-family: 'Gotham Greek Medium';
   gap: 10px;
}

.skroutz-note {
   background-color: #d4d2d2;
   border-radius: var(--border-radius);
   padding: 8px;
}

/*==============
Help page
===============*/
.help-section {
   margin-bottom: 40px;
}

.help-title-section {
   font-family: 'Gotham Greek Medium';
   font-size: 1.4rem;
}

.help-text-section {
   font-family: 'Gotham Greek Book';
   font-size: 0.9rem;
   letter-spacing: 1px;
   display: flex;
   flex-direction: column;
   gap: 10px;
   line-height: 1.6;
}

/*==============
Responsive
===============*/

@media screen and (max-width: 1280px) {
   .home-last-three-months .stats-items {
      width: unset;
   }
}

@media screen and (max-width: 1560px) {
   .toggle-Menu {
      display: block;
      left: 20px;
      top: 20px;
      z-index: 100;
      width: fit-content;
   }
   .nav-logo {
      margin-top: 60px;
   }
   .nav-svg {
      margin-top: 60px;
   }
   .top-left-corner {
      margin-top: 60px;
   }
   /* Navbar */
   .header {
      width: 350px;
      position: absolute;
      left: -100%;
      min-height: 100vh;
      background-color: var(--main);
      transition: all 0.3s ease-in-out;
   }
   .header-active {
      width: 250px;
      position: absolute;
      left: 0px;
      min-height: 100vh;
      background-color: var(--main);
      z-index: 10;
      transition: all 0.3s ease-in-out;
   }
}

@media screen and (max-width: 1130px) {
   .customers-container {
      min-width: 100%;
   }

   .flex-center {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
   }
}

@media screen and (max-width: 960px) {
   /* payment-type */
   .payment-type {
      display: flex;
      flex-direction: column;
   }
}

@media screen and (max-width: 767px) {
   .header-active {
      width: 250px;
      position: fixed;
      left: 0px;
      min-height: 100vh;
      background-color: var(--main);
      z-index: 10;
      transition: all 0.3s ease-in-out;
   }

   .open-x {
      position: fixed !important;
   }

   .customer-search {
      width: 80%;
   }

   .cp-name {
      width: 250px;
   }

   .payment-type select {
      width: 90%;
   }

   .customers-top-btns {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
   }

   .create-invoices {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
   }

   .label-flex-input {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
   }

   .customers-container {
      min-width: 250px;
   }

   .customer-info {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
   }

   .customer-info-data {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 220px;
      max-width: 220px;
      overflow: hidden;
   }
   .customer-info-data input {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 220px;
      max-width: 220px;
      overflow: hidden;
   }

   .calendar-input {
      max-width: 212px;
   }

   .box-login {
      padding: 40px 60px 60px 60px;
   }

   .mg-left-10 {
      margin-left: unset !important;
   }

   .create-customer-form input {
      width: 270px;
   }

   .page-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
   }

   .bg-red {
      background-color: #a51c1c;
      color: #fff;
      padding: 4px;
      border-radius: 4px;
   }
   .usefullLinks-container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
   }
   .usefullLink:nth-child(3),
   .usefullLink:nth-child(4) {
      margin-top: 40px;
   }

   .skroutz-order-state {
      border-radius: 10px;
      margin-left: 0;
      padding: 4px 10px;
   }

   .skroutz-flex-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 15px;
   }

   .skroutz-right-column {
      width: 100%;
   }

   .flex-column {
      width: unset;
   }

   .skroutz-single-product-name-quantity .flex-center {
      flex-direction: row;
      gap: 2px;
      align-items: flex-start;
   }

   .skroutz-single-product-name-quantity {
      padding: 15px;
      gap: 4px;
   }

   .skroutz-item-price {
      display: flex;
      align-items: center;
   }

   .toggle-Menu.fixed {
      position: fixed;
   }

   .top-left-corner {
      margin-top: 70px;
   }

   .customer-search,
   .calendar-input {
      margin: unset;
   }
   .rdrCalendarWrapper {
      margin-top: 15px;
   }

   .invoices-mobile-list {
      margin-top: 20px;
   }
}

@media only screen and (min-width: 1024px) {
   .invoices-mobile-list {
      display: none !important;
   }

   .skroutz-marketplace-box {
      display: grid;
      justify-items: start;
      align-items: center;
      grid-template-columns: repeat(11, 1fr);
      grid-row: 1;
      transition: all 0.2s ease-in;
   }

   .skroutz-marketplace-box:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-in;
   }

   .grid-span-two {
      grid-column: span 2;
   }

   .skroutz-marketplace-box > * {
      font-size: 14px;
   }

   .image-type {
      width: unset;
   }

   .mobile-title {
      display: none;
   }

   .invoices-list-table {
      display: grid;
      justify-items: start;
      align-items: center;
      grid-template-columns: 2fr 2fr 2fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr;
      grid-row: 1;
   }

   .skroutz-client-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
}

@media only screen and (max-width: 1024px) {
   .mobile-h {
      display: none;
   }
}

.bg-f3f2f2 {
   background-color: #f3f2f2;
   color: var(--main);
   border: unset;
}
